import { getData, minimumCacheTime } from './utils/data'
import { extend } from './utils/objects'
import storage from 'local-storage-fallback'
import { kolWarn as warn } from './utils/debugging'
import { modes } from './utils/mode'

export default class CampaignOptions {
  constructor (options) {
    const envOptions = this.defaultEnvOptions[_kol.env || 'production']
    this.options = extend(
      {},
      this.defaultOptions,
      envOptions || {},
      options,
      window.kolOptionOverrides || {}
    )

    window._kol.mode = this.mode = modes(this.options.mode)
  }

  // modes
  // standard: DEFAULT Runtime
  // preview-lead:  This will set the lead as the not-anonymous preview
  // preview-anonymous: This will set the lead as

  defaultOptions = {
    mode: 'standard',
    reCAPTCHA_site_key: '6LeHFokUAAAAABceXPpNEEuvJcBwxtcDYB1_nVc6' // V3 KEY!
  }

  defaultEnvOptions = {
    production: {
      campaignOptions: {
        url: 'https://options.kickoffpages.com',
        cacheFor: 10
      },
      fileStack: {
        apiKey: 'AWYMNXoERfyMd4yDUS2qwz'
      },
      leads: {
        url: 'https://leads.kickofflabs.com'
      },
      analytics: {
        url: 'https://api.kickofflabs.com',
        trackView: true
      },
      shopify: {
        url: 'https://app.kickofflabs.com/__shopify'
      },
      embed: {
        url: 'https://embed.kickoffpages.com'
      }
    },
    staging: {
      campaignOptions: {
        url: 'https://options.stagingkickoffpages.com',
        cacheFor: 10
      },
      fileStack: {
        apiKey: 'ANGUrNouCRLCikZUk5Mywz'
      },
      leads: {
        url: 'https://leads.stagingkickofflabs.com'
      },
      analytics: {
        url: 'https://api.stagingkickofflabs.com',
        trackView: true
      },
      shopify: {
        url: 'https://app.stagingkickofflabs.com/__shopify'
      },
      embed: {
        url: 'https://embed.stagingkickoffpages.com'
      }
    },
    development: {
      campaignOptions: {
        url: 'http://options.kickoffpages.test',
        cacheFor: 0
      },
      fileStack: {
        apiKey: 'ANGUrNouCRLCikZUk5Mywz'
      },
      leads: {
        url: 'http://leads.kickoff.test'
      },
      analytics: {
        url: 'http://kickoffapi.localhost',
        trackView: false
      },
      shopify: {
        url: 'http://app.kickoff.test/__shopify'
      },
      embed: {
        url: 'http://embed.kickoffpages.test'
      }
    }
  }

  load () {
    return this.cachedOptionsData(this.options.campaignOptions.cacheFor)
  }

  /*
   * Pass a Campaign ID (or just lookup current options in cookies and get the cached  data if it exists.
   * ageAllowed is how old you are willing to let the data be in seconds.
   * Returns a promise you can .then off of.
   */
  cachedOptionsData (ageAllowed = 300) {
    if (window.kolCampaignOptions) {
      return Promise.resolve(
        extend({}, window.kolCampaignOptions, { valid: true })
      )
    }

    const cacheSeconds = minimumCacheTime(ageAllowed)

    let optionsData = storage.getItem(
      `kola.${this.options.campaignId}.options.data`
    )

    if (optionsData) {
      optionsData = JSON.parse(optionsData)
      if (Date.now() - optionsData.queryDateTime <= cacheSeconds * 1000) {
        return Promise.resolve(optionsData)
      }
    }

    return this.currentOptions()
  }

  currentOptions () {
    return this.fetch(this.options.campaignId)
      .then(this.applyData.bind(this))
      .catch((err) => {
        warn(`Error looking up Configuration: ${err.message}`)
        return Promise.resolve(extend({}, this.options, { valid: false }))
      })
  }

  applyData (optionsData) {
    if (optionsData.error) {
      return extend({}, this.options, { valid: false })
    }
    const newOptions = extend({}, optionsData, this.options, { valid: true })
    this.cacheOptionsData(newOptions, 'options')
    return newOptions
  }

  fetch () {
    const url = this.buildUrl()
    return getData(url)
  }

  buildUrl () {
    return `${this.options.campaignOptions.url}/${this.options.campaignId}/`
  }

  cacheOptionsData (optionsData, type = 'options') {
    optionsData.queryDateTime = Date.now()
    storage.setItem(
      `kola.${this.options.campaignId}.${type}.data`,
      JSON.stringify(optionsData)
    )
  }
}
